export default {
  "article_title_1": "金豐來推比特幣1安士9999純金紀念幣 料金價2,000-2,600美元上落",
  "article_title_2": "黄金实时行情分析：黄金价格逐渐走高 多头将瞄准去年12月28日高点2088美元",
  "article_title_3": "黄金TD开启小幅涨势 聚焦鲍威尔的讲话",
  "article_title_4": "美联储保持利率不变 黄金TD开启小幅涨势",
  "article_title_5": "经济数据发布黄金缓涨",
  "article_title_6": "金市展望：多头幸存下来！非农和美联储冲击黄金 这一危机恐引爆涨势",
  "article_1_1": "金銀業貿易場行政總裁馮煒能(右二)出席支持活動，並與金豐來有限公司負責人張明德(左二)及一眾來賓合照。 <br/> <br/>  金豐來日前舉辦「2024比特幣1安士9999純金紀念幣」上市發佈會，該主題是「黃金有價，歷史無價」。一枚紀念幣定價約為2,500美元，折合約2萬元，屆時將根據金價作出調整。對於金價未來一季的預測，金豐來負責人張明德預計，未來數個月內金價每安士料介乎2000-2600美元，或達到2,800美元。",
  "article_1_2": "\n\n該產品為9999純金鑄造，每枚淨重一金衡安士，這也標誌比特幣銘文紀念品的誕生，售附NFT參與全新GameFi項目，且購買清單將以比特幣銘文紀錄。每一枚幣上都有獨特的數字，目前較好的數字已被訂完。 <br/>\n\n張明德表示，此次紀念幣的發布意義非凡，2024年標著著比特幣的三大里程：1）慶祝發行15周年；2）美國現貨ETF獲批，流入傳統市場；3）比特幣迎來第四次產量減半。<br/>\n\n當日會上舉辦慈善義賣，一枚幣以2 萬元起拍，有幸運兒即場以2.68 萬元投得此具有非凡意義的紀念幣。<br/>",
  "article_1_3": "<img data-v-f385ed7c=\"\" src=\"/images/article-1-1.jpeg\" class=\"img-responsive\" alt=\"post-img\">",
  "article_1_4": "\n<br/>\n============= <br/>\n深入閱讀政經生活文化，更多內容盡在：<br/>\nWebsite：<a href=\"https://www.capital-hk.com\" target=\"_blank\">www.capital-hk.com</a> <br/>\r\nFacebook：<a href=\"https://www.facebook.com/CapitalPlatformHK\" target=\"_blank\">www.facebook.com/CapitalPlatformHK</a> <br/>\r\nInstagram：<a href=\"https://www.instagram.com/capital.ceo.entrepreneur\" target=\"_blank\">www.instagram.com/capital.ceo.entrepreneur</a> <br/>\r\nLinkedIn：<a href=\"https://www.linkedin.com/company/capitalhk/\" target=\"_blank\">www.linkedin.com/company/capitalhk/</a> <br/>\n",
  "article_2_1": "周五（2月2日），现货黄金价格交投于2056美元盎司附近，黄金价格逐渐走高，此前在交易日早些时候触及2065美元/盎司，为自1月3日以来的最高水平，接近一个月来的高点。",
  "great_comment": "精彩評論",
  "no_comment": "暫時沒有評論哦~",
  "write_comment": "寫評論",
  "info_sharing": "资讯分享",
  "likes": "贊",
  "brand_name": "金豐來",
  "read": "閱讀",
  "disclaimer": "免责申明:",
  "footer_disclaimer": " 本網站上的資訊可能會向您介紹由金豐來有限公司 Gold Fun Corporation Limited 或其任何 附屬或關聯公司(統稱「GF Corp」)提供的某些貴金屬投資相關產品和服務。 網站上發佈的任何資訊均不構成進行任何交易的招攬、要約或建議。 這些網頁中引用的產品、服務、資訊和/或資料可能不適用於某些司法管轄區(包括但不限於美國、加拿大、日本)的所有  個人或居民。 在造訪這些網頁中引用的產品、服務、資訊和/或資料之前,請確保您不受所在司法管轄區的法律限制。",
  "header_1": "香港金銀業貿易場行員",
  "header_2": "香港海關註冊號碼",
  "flexible_msg": "買賣靈活,成就未來",
  "go_to_one_like": "前往 OneLike Shop",
  "go_to_sampattisafar": "前往 SampattiSafar",
  "about_gold_fun": "關於金豐來",
  "about_gold_fun_1": " 金豐來有限公司(Gold Fun Corporation Ltd),成立於2016年,是香港金銀業貿易場正式行員(行員編號:064,營業狀況類別:B),亦是經香港海關註冊的 A 類註冊商。一直專注於貴金屬業務,我們提供一站式全方位服務,包括黃金的精煉、生產、貿易、投資,以及市場行銷及價位對沖等。從金礦源頭到純金製品,從押運倉存到投資對沖,我們的專業團隊及顧問皆能為客戶量身訂做各類貴金屬貿易及投資方案,安全可靠。",
  "about_gold_fun_2": " 近年來,我們在香港與多家領先的熔煉服務公司建立業務合作,日均處理量大增,交收快捷妥當。展望未來,金豐來會根據市場最新趨勢,致力於改善業務結構並把握新興產業的機遇。我們積極拓展本地及國際市場,尋找可靠的合作夥伴,全面推動公司發展。我們的團隊以誠實、積極和專業的態度,為客戶提供穩定、高效且快速的服務,應對日新月異的國際貴金屬市場。",
  "local_melting_service": "本地熔煉服務",
  "local_melting_msg": "透過合作夥伴,為海外及本地黃金貨源提供一站式熔煉服務,包括押運、清關、倉存、交割",
  "hedge_service": "對沖服務",
  "hedge_service_msg": "透過「豐來金」交易,為實金客戶提供對沖服務,交易簡易靈活,減低價格變動風險",
  "solid_gold_sale": "實金買賣",
  "solid_gold_sale_msg": "千足純金、公斤條、品牌條,一應俱全,交易量大,交收快捷安全",
  "gfgold_msg": "杠杆型合約投資產品,短線投資、實金對沖,費率特低,靈活易用",
  "about_gold_fun_detail": "金豐來有限公司(Gold Fun Corporation Ltd),成立於2016年,是香港金銀業貿易場正式行員(行員編號:064,營業狀況類別:B),亦是經香港海關註冊的 A 類註冊商。一直專注於貴金屬業務,我們提供一站式全方位服務,包括黃金的精煉、生產、貿易、投資,以及市場行銷及價位對沖等。從金礦源頭到純金製品,從押運倉存到投資對沖,我們的專業團隊及顧問皆能為客戶量身訂做各類貴金屬貿易及投資方案,安全可靠。",
  "click_to_see": "點擊查看更多",
  "login": "交易賬戶 （登錄 / 開戶)",
  "more": "了解更多",
  "latest_news": "最新资讯",
  "column_analysis": "專欄分析",
  "analysis_title_1": "2024-02-02 成報專欄《真金白銀》—中東局勢惡化 黃金避險",
  "analysis_title_2": "2024-01-26 成報專欄《真金白銀》—PMI 數據強勁 金價走低",
  "analysis_title_3": "2024-01-19 成報專欄《真金白銀》—美零售數據強勁 金價下滑",
  "analysis_title_4": "2024-01-12 成報專欄《真金白銀》—美國CPI來襲 金價暫穩據",
  "service_subtitle_1": "專家重熔服務可重新利用您的黃金。",
  "service_subtitle_2": "精緻的鑽石珠寶，永恆的優雅。",
  "service_subtitle_3": "適合嚴肅投資者的高品質 12 公斤金條。",
  "service_subtitle_4": "原始的 24 克拉金條，適合安全投資。",
  "service_subtitle_5": "用於即時黃金交易分析的先進軟體。",
  "service_subtitle_6": "未來的黃金合約可確保您的投資安全。",
  "service_title_1": "熔炼服务",
  "service_title_2": "钻石首饰",
  "service_title_3": "12公斤金块",
  "service_title_4": "纯金金条",
  "service_title_5": "即时交易软件",
  "service_title_6": "黄金期货",
  "core_services": "核心服務",
  "welcome_to_goldfun": "歡迎來到金豐來",
  "banner_msg_1": "投資確定性，黃金就是未來",
  "personal_consultant": "個人黃金投資顧問",
  "banner_msg_2": "有黃金投資問題嗎？",
  "finanance_strength": "資金實力是我們成功的基石",
  "our_services": " 我們的服務",
  "contact_us": "聯絡我們",
  "learn_more": "了解更多",
  "address": "地址",
  "contact": "聯絡",
  "opening_time": "營業時間",
  "email": "電子郵件",
  "view_all_news": "查看所有新聞",
  "onelike_desc": "OneLike Shop 是與金豐來合作的網上商城，商城內的貴金屬製品、翡翠玉器、美鑽首飾、金條金磚等，均由金豐來全力提供，貨真價實，應有盡有，優質保證。",
  "sampatti_desc": "SampattiSafar由Gold Fun Corporation Ltd開發，是一個專為印度市場量身定制的平台，旨在促進貴金屬交易，利用國際定價標準來保護利益。 它透過整合本地支付網關進行 24/7 交易，簡化了投資流程，允許投資低至 200 印度盧比，並具有自動機制，可最大限度地減少損失並確保利潤。 專為方便、高效而設計，提供快速結算和輕鬆帳戶管理，讓用戶更輕鬆地從事貴金屬交易，以最小的障礙實現財務目標。",
  "gfgold_desc": "這項金融服務具有顯著的優勢，允許投資者透過槓桿黃金/白銀合約以最小的努力實現實質成果，僅需要 2% 的最低保證金。 它提供交易日24小時交易的便利，使交易者能夠抓住每一個機會。 此外，該服務還擁有極低的交易費用，並在達到一定數量後提供免佣金交易的福利。 此外，它還提供靈活的結算選項，允許以現金或實體黃金結算，滿足短期投資策略和實體對沖需求。 對於那些希望最大限度地提高投資效率和靈活性的人來說，這種功能組合使其成為一個有吸引力的選擇。",
  "business_associates": "商業夥伴",
  "service_title_7": "倉儲服務",
  "service_title_8": "量身訂製的交易",
  "service_title_9": "GF 黃金貿易",
  "service_title_10": "黃金投資",
  "service_title_11": "礦山中的金礦石",
  "service_subtitle_7": "贵重物品的安全储存解决方案",
  "service_subtitle_8": "贵金属个性化交易计划",
  "service_subtitle_9": "GF黄金交易，专为精英投资者",
  "service_subtitle_10": "全面黄金投资服务与咨询",
  "service_subtitle_11": "直接获取知名矿山的优质金矿石",
  "home": "首頁",
  "hedging_faq": "對沖 FAQ",
  "hedging_faq_title": "黃金對沖服務由",
  "what_is_hedging": "什麼是「對沖」？",
  "hedging_msg_1": "對沖是一種風險管理策略，用於減少\r\n                             金融市場價格波動的影響。 它涉及採取抵消立場\r\n                             與原始頭寸負相關的金融工具，從而\r\n                             減少市場波動帶來的潛在損失。 對沖的目標是盡量減少\r\n                             市場風險對投資組合的影響，確保任何不利的價格變動\r\n                             一種產品的收益部分或全部被另一種產品的收益所抵銷。",
  "hedging_msg_2": "身為實體黃金交易者，如何\r\n                                     您使用 Gold Fun 的交易帳戶進行避險嗎？",
  "hedging_msg_3": "實體黃金交易者可以使用交易帳戶進行對沖\r\n                             他們透過在黃金合約中持有抵銷頭寸來承受黃金價格的風險。",
  "hedging_msg_4": "為了\r\n                                         購買黃金並等待交割的人：",
  "hedging_msg_5": "如果交易者做多實體黃金並擔心\r\n                             對於潛在的價格下跌，他們可以出售黃金合約以鎖定銷售價格\r\n                             他們的實物資產。 如果黃金價格確實下跌，其實物損失\r\n                             黃金持有量將被其合約部位的收益所抵銷。",
  "hedging_msg_6": "為了\r\n                                         賣出產品後需要購買黃金作為原料的：",
  "hedging_msg_7": "如果交易者做空實體黃金並擔心\r\n                             對於潛在的價格上漲，他們可以購買黃金合約以限制潛在的損失。\r\n                             使用交易帳戶進行避險可以幫助交易者管理風險並鎖定\r\n                             利潤。",
  "hedging_msg_8": "對沖的最佳時機是什麼以及什麼時候是最好的\r\n                                     清算我的對沖部位？",
  "hedging_msg_9": "對沖通常用於管理風險，因此\r\n                             對沖決定應基於對市場環境和投資者的評估\r\n                             對未來價格走勢的看法，但通常情況下，一旦客戶暴露，就應該這樣做\r\n                             價格變動的風險。",
  "hedging_msg_10": "對於避險部位的平倉，最佳時機是\r\n                             當原始部位不再需要市場風險保護時，或者\r\n                             當市場條件改變以致不再需要或不再需要對沖時\r\n                             有效管理風險。",
  "hedging_msg_11": "使用停損單或其他風險管理\r\n                             策略也可能適合幫助管理風險並確保及時退出對沖，\r\n                             但我們建議客戶在其原始實物交割時清算其對沖頭寸\r\n                             職位也已被清除。",
  "hedging_msg_12": "圖一︰要全面對沖價位風險所需之手數對算表",
  "gf_gold_title": "與 GF Gold 一起塑造您的未來",
  "gf_gold_msg_1": "事半功倍",
  "gf_gold_msg_2": "投資槓桿金/白銀合約",
  "gf_gold_msg_3": "最低保證金要求僅 2%",
  "gf_gold_msg_4": "隨時隨地交易",
  "gf_gold_msg_5": "交易日24小時交易抓住每一個機會",
  "gf_gold_msg_6": "極低的交易費用",
  "gf_gold_msg_7": "達到特定交易量免佣金",
  "gf_gold_msg_8": "現金或實物結算",
  "gf_gold_msg_9": "現金結算，或全額付款後選擇實體黃金結算",
  "gf_gold_msg_10": "短期投資或實物對沖的理想選擇",
  "seo_1": "金豐來有限公司(Gold Fun Corporation Ltd),成立於2016年,是香港金銀業貿易場正式行員(行員編號:064,營業狀況類別:B),亦是經香港海關註冊的 A 類註冊商。一直專注於貴金屬業務,我們提供一站式全方位服務,包括黃金的精煉、生產、黃金貿易、黃金投資,以及市場行銷及價位對沖等。從金礦源頭到純金製品,從押運倉存到投資對沖,我們的專業團隊及顧問皆能為客戶量身訂做各類貴金屬貿易及投資方案,安全可靠。",
  "seo_2": "香港頂級黃金交易平台",
  "seo_3": "透過我們簡單易用的網路平台，客戶可以輕鬆買賣及交易黃金。 它提供先進的圖表工具和即時市場數據。\r",
  "about_us_title": "關於金豐來GoldFun - 香港黃金交易經紀商",
  "about_us_p1": "金豐來有限公司(Gold Fun Corporation Ltd),成立於2016年,是香港金銀業貿易場正式行員(行員編號:064,營業狀況類別:B),亦是經香港海關註冊的 A 類註冊商。一直專注於貴金屬業務,我們提供一站式全方位服務,包括黃金的精煉、生產、黃金貿易、黃金投資,以及市場行銷及價位對沖等。從金礦源頭到純金製品,從押運倉存到投資對沖,我們的專業團隊及顧問皆能為客戶量身訂做各類貴金屬貿易及投資方案,安全可靠。\r",
  "about_us_p2": "近年來,我們在香港與多家領先的熔煉服務公司建立業務合作,日均處理量大增,交收快捷妥當。展望未來,金豐來會根據市場最新趨勢,致力於改善業務結構並把握新興產業的機遇。我們積極拓展本地及國際市場,尋找可靠的合作夥伴,全面推動公司發展。我們的團隊以誠實、積極和專業的態度,為客戶提供穩定、高效且快速的服務,應對日新月異的國際貴金屬市場。\r",
  "about_us_p3": "使命宣言",
  "about_us_p4": " 在GoldFun，我們的目標是讓機構和個人輕鬆存取透明的黃金交易平台，以便他們能夠在不斷變化的金融環境中保護和增加自己的財富。\n",
  "about_us_p5": "儲存解決方案",
  "about_us_p6": "GoldFun 與知名儲存設施合作，為客戶的實體黃金持有提供安全且有保險的儲存選項，確保安心並防止盜竊或遺失。",
  "about_us_p7": "香港頂級黃金交易平台",
  "about_us_p8": "透過我們簡單易用的網路平台，客戶可以輕鬆買賣及交易黃金。 它提供先進的圖表工具和即時市場數據。\r",
  "about_us_p9": "個人化支援",
  "about_us_p10": "我們經驗豐富的專家團隊致力於為每位客戶提供個人化的關注。 我們透過提供投資組合分析、客製化投資建議和指導來幫助客戶最大限度地發揮其黃金交易技巧來做到這一點。",
  "about_us_p11": "追求卓越",
  "about_us_p12": "在我們所有的互動中，GoldFun 都堅持誠實、開放和能力的最高標準。 我們的使命是在相互尊重、信任和共同渴望財務成功的基礎上與客戶建立長期關係。",
  "about_us_p13": "立即加入 GoldFun，開始探索黃金交易的經濟效益。\r",
  "risk_closure": "風險聲明：貴金屬交易會為您的資金帶來很高的風險，您應該只用您可以承受損失的資金進行交易。貴金屬交易可能不適合所有投資者，因此請確保您完全了解所涉及的風險，並在必要時尋求獨立建議。請閱讀並確保您完全理解我們的風險聲明。",
  "privacy_policy": "私隱政策",
  "terms": "使用條款"
};