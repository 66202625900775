<template>
    <a v-if="!isMobile" href="https://members.goldfun24k.com/" target="_blank" rel="noreferrer noopener"
        class=" login-btn btn btn-default">{{ $t('login') }}</a>

    <a v-else href="https://members.goldfun24k.com/" target="_blank" rel="noreferrer noopener"
        class=" login-btn-mobile btn btn-default">{{ $t('login') }}</a>
    <div id="sticker-sticky-wrapper" class="sticky-wrapper" style="height: 121px;">
        <nav id="sticker" class="navbar navbar-default" ref="navbar">
            <div class="container">
                <div class="navbar-header">
                    <button type="button" @click.prevent="globalState.isMenuOpen = !globalState.isMenuOpen"
                        class="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1"
                        aria-expanded="true">
                        <span class="sr-only">Toggle navigation</span>
                        <span class="ion-android-menu"></span>
                    </button>
                    <!-- LOGO -->
                    <a class="navbar-brand" href="#" @click.prevent=" globalState.isMenuOpen = false; $redirectTo('/')"
                        style="display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: center;
    padding: 1em 0;
    margin-top: 1.2em !important;">
                        <img width="80" class="img-responsive" alt="logo" src="../assets/images/logo.gif">
                        <span class="text-color" style="font-size: 12px;
    padding-top: 10px;">金豐來有限公司</span>
                        <span class="text-color" style="font-size: 14px;
    font-weight: 600;">Gold Fun Corporation Ltd</span>
                    </a>
                </div>
                <div v-if="!isMobile">
                    <div class="navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul class="nav navbar-nav menu-inline navbar-right">
                            <li class="dropdown">
                                <a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/')">{{
                                    $t('home')
                                    }}</a>

                            </li>

                            <li><a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/aboutus')">{{
                                $t('about_gold_fun') }}</a></li>

                            <li>
                                <a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/services')">{{
                                    $t('core_services') }}</a>



                            </li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo('/contactus')">{{
                                        $t('contact_us') }}</a>
                            </li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo('/hedging-faq')">{{
                                        $t('hedging_faq') }}</a></li>
                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo(`/privacy-policy`)">{{
                                        $t('privacy_policy') }}</a></li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo(`/terms`)">{{
                                        $t('terms') }}</a></li>

                        </ul>
                    </div>
                </div>
                <div v-else>
                    <div class="navbar-collapse  custom-menu" id="bs-example-navbar-collapse-1"
                        v-show="globalState.isMenuOpen">
                        <ul class="nav navbar-nav menu-inline navbar-right">
                            <li class="dropdown">
                                <a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/')">{{
                                    $t('home')
                                    }}</a>

                            </li>

                            <li><a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/aboutus')">{{
                                $t('about_gold_fun') }}</a></li>

                            <li>
                                <a href="#" @click.prevent="globalState.isMenuOpen = false; $redirectTo('/services')">{{
                                    $t('core_services') }}</a>
                            </li>


                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo('/contactus')">{{
                                        $t('contact_us') }}</a>
                            </li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo('/hedging-faq')">{{
                                        $t('hedging_faq') }}</a></li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo(`/privacy-policy`)">{{
                                        $t('privacy_policy') }}</a></li>

                            <li><a href="#"
                                    @click.prevent="globalState.isMenuOpen = false; $redirectTo(`/terms`)">{{
                                        $t('terms') }}</a></li>

                        </ul>
                    </div>
                </div>

            </div>
        </nav>
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { globalState } from '../store/globalState'

import { useI18n } from 'vue-i18n';

const langCode = ref('');
const { locale } = useI18n();

const navbar = ref(null); // This is to reference the navbar element
const isMobile = ref(false);
const screenWidth = ref(window.innerWidth); // Add this line to track screen width

// Detect device type based on screen width rather than user agent
const detectDeviceType = () => {
    isMobile.value = screenWidth.value <= 767; // Example breakpoint at 767px
}

// Update screen width
const updateScreenWidth = () => {
    screenWidth.value = window.innerWidth;
    detectDeviceType();
}

// Existing code for handleClickOutside
const handleClickOutside = (event) => {
    if (navbar.value && !navbar.value.contains(event.target) && globalState.isMenuOpen.value) {
        globalState.isMenuOpen.value = false;
    }
};

onMounted(() => {
    updateScreenWidth(); // Set initial screen width
    window.addEventListener('resize', updateScreenWidth); // Adjust screen width on resize
    document.addEventListener('click', handleClickOutside);

    langCode.value = locale.value === 'zh-TW' ? 'cn' : 'en';
});

onUnmounted(() => {
    window.removeEventListener('resize', updateScreenWidth); // Clean up event listener
    document.removeEventListener('click', handleClickOutside);
});
</script>



<style>
.navbar-collapse {

    display: block;
}

@media screen and (max-width: 767px) {

    .navbar-default .navbar-collapse {
        border: none;
        box-shadow: none;
    }

    .custom-menu {
        background: black;
        position: absolute;
        right: 5%;
        width: 300px;
        top: 50%;
    }

}

.login-btn-mobile {
    position: fixed;
    right: 10px;
    z-index: 99999999999999;
    bottom: 10%;
    border-radius: 10px !important;
    height: 37px !important;
    font-size: 16px !important;
    line-height: 36px !important;
    font-weight: 600 !important;
    box-shadow: rgb(22 22 23) 0px 7px 29px 0px;

}

.login-btn {
    position: fixed;
    right: 10px;
    z-index: 99999999999999;
    bottom: 10%;
    border-radius: 10px !important;
    height: 57px !important;
    font-size: 19px !important;
    line-height: 52px !important;
    font-weight: 600 !important;
    box-shadow: rgb(22 22 23) 0px 7px 29px 0px;
}
</style>