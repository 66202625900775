<template>
    <footer class="black-bg typo-white parallax-bg" style=" background-position: center center;
    background-repeat: no-repeat;    
   background-image: linear-gradient(rgb(2 2 2 / 55%), rgb(0 0 0 / 70%)), url(/images/footer.png);">
        <div class="footer-widget-area " data-animation="fadeInUp" data-animation-delay="300">
          
            
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12" style="text-align: center;">
                        <h3 style="color: #E9A221;">{{ $t('business_associates')}}</h3>
                        <div class="grid-container">
                            <div  v-for="image in images" :key="image">
                            <img :src="image"  class="footer-img">
                         </div>
                        </div> 
                        
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-widget-area " data-animation="fadeInUp" data-animation-delay="300">
            <div class="container">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <p >
                           
                            <span > {{ $t('risk_closure')  }}</span>
                        </p>

                        <p >
                            <span style="font-weight:bold;">
                                <span>{{ $t('disclaimer')}}</span>
                            </span>
                            <span > {{ $t('footer_disclaimer')  }}</span>
                        </p>
                    </div>
                </div>

                <div class="row">
                  
                    <div class="pull-left download-container">
							<a class=" icon-appStore-footer"  href="https://download.mql5.com/cdn/mobile/mt5/ios?server=GoldFunCorporation-Server">
                                <img src="../assets/images/apple.png" width="100" height="30">
                            </a>
							<a class=" icon-appStore-footer"   href="https://download.mql5.com/cdn/mobile/mt5/android?server=GoldFunCorporation-Server">
                                <img src="../assets/images/en_badge_web_generic.png" width="100" height="30">
                            </a>
							<a class=" icon-appStore-footer"   href="https://download.mql5.com/cdn/web/gold.fun.corporation/mt5/goldfuncorporation5setup.exe">
                                <img src="../assets/images/downloadbutton.png" width="100" height="30">
                            </a>
						</div>
                </div>
            </div>
        </div>
        <div class="container">
            <hr>
        </div>
        <div class="footer-bottom-area">
            
            <div class="container">
                <div class="row ">
                    <div class="col-md-6 footer-logo ">
                        <a class="navbar-brand" href="#" @click="$redirectTo('/')">
                            <img width="80" class="img-responsive" alt="logo" src="../assets/images/logo.gif" style="height: auto !important;">

                        </a>
                    </div>
                    <div class="col-md-6 footer-info">
                        <p>©2016-2025 金豐來有限公司 Gold Fun Corporation Ltd 版權所有。
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script setup>

import { ref, onMounted } from 'vue';
const images = ref([]);

onMounted(() => {
    const imageContext = require.context(
        '@/assets/images/footer',
        false,
        /\.(jpe?g|png|gif|svg)$/
    );

    // Sort the image keys by name
    const sortedImageKeys = imageContext.keys().sort((a, b) => {
        // You might need to adjust the sorting logic based on your actual key structure
        // This assumes the keys are simple file names
        return a.localeCompare(b);
    });

    // Map the sorted keys to their image URLs
    const loadedImages = sortedImageKeys.map((key) => {
        const imageUrl = imageContext(key);
        return imageUrl;
    });

    images.value = loadedImages;
});



</script>

<style scoped>

.footer-img{
    max-width: 150px;
}

.grid-container{
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    gap: 10px;
}

p{
    font-size: 13px;
}

.icon-appStore-footer{
    background: black;
    padding: 5px 10px;
    border-radius: 8px;

}

.download-container{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    align-items: center;
}

@media (max-width: 768px) {
    p{
    font-size: 12px;
}
    .footer-img{
    max-width: 100px;
}
.footer-bottom-area p:last-child {
    font-size: 10px;
}
    .footer-widget-area{
        padding: 45px 0 20px !important;
    }
    .grid-container{
    
        grid-template-columns: repeat(3, 1fr);
}
.black-bg{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-size: cover;  
}
.footer-img{
   margin: 10px 0;
}

.download-container{
    width: 100%;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
}

</style>


  