export default {
  "article_title_1": "Gold Fun launches Bitcoin 1oz 9999 pure gold commemorative coin. Gold price is expected to fluctuate between 2,000-2,600 US dollars.",
  "article_title_2": "Real-time gold market analysis: Gold prices are gradually rising, and bulls will target the high of $2,088 on December 28 last year.",
  "article_title_3": "Gold TD starts slight gains, focusing on Powell's speech",
  "article_title_4": "Fed keeps interest rates unchanged, gold TD starts slight gains",
  "article_title_5": "Gold eases gains after economic data releases",
  "article_title_6": "Gold market outlook: Bulls survive! Non-farm payrolls and the Fed hit gold. This crisis could trigger a rally.",
  "article_1_1": "Feng Weineng (second from right), CEO of the Chinese Gold & Silver Trade Center, attended the support event and took a photo with Zhang Mingde (second from left), the person in charge of Gold Fun Co., Ltd., and other guests. <br/> <br/>  Gold Fun recently held a launch conference for the \"2024 Bitcoin 1 oz 9999 Pure Gold Commemorative Coin\". The theme was \"Gold has a price, history is priceless.\" \nA commemorative coin is priced at approximately US$2,500, equivalent to approximately 20,000 yuan, and will be adjusted based on the price of gold. \nRegarding the forecast for gold prices in the coming quarter, Zhang Mingde, head of Jinfenglai, predicts that the gold price per ounce is expected to be between US$2,000-2,600 per ounce in the next few months, or reach US$2,800.",
  "article_1_2": "\nThis product is made of 9999 pure gold, with a net weight of one troy ounce each. This also marks the birth of Bitcoin-inscribed souvenirs. It is sold with NFT to participate in the new GameFi project, and the purchase list will be recorded with Bitcoin inscriptions. Each coin has a unique number on it, and the better numbers are currently sold out. <br/>\nZhang Mingde said that the release of this commemorative coin is of great significance. 2024 marks three major milestones for Bitcoin: 1) celebrating the 15th anniversary of issuance; 2) the US spot ETF was approved and flowing into the traditional market; 3) Bitcoin ushered in its third anniversary. Production was cut in half four times. <br/>\nA charity sale was held at the meeting that day. The starting price for a coin was NT$20,000. A lucky person bought this commemorative coin of extraordinary significance for NT$26,800 on the spot. <br/>",
  "article_1_3": "<img data-v-f385ed7c=\"\" src=\"/images/article-1-1.jpeg\" class=\"img-responsive\" alt=\"post-img\">",
  "article_1_4": "\n<br/>\n============= <br/>\nRead more about politics, economy, life and culture. More content is available at: <br/>\nWebsite：<a href=\"https://www.capital-hk.com\" target=\"_blank\">www.capital-hk.com</a> <br/>\r\nFacebook：<a href=\"https://www.facebook.com/CapitalPlatformHK\" target=\"_blank\">www.facebook.com/CapitalPlatformHK</a> <br/>\r\nInstagram：<a href=\"https://www.instagram.com/capital.ceo.entrepreneur\" target=\"_blank\">www.instagram.com/capital.ceo.entrepreneur</a> <br/>\r\nLinkedIn：<a href=\"https://www.linkedin.com/company/capitalhk/\" target=\"_blank\">www.linkedin.com/company/capitalhk/</a> <br/>\n",
  "article_2_1": "On Friday (February 2), spot gold prices traded around $2,056 an ounce, with gold prices gradually moving higher after hitting $2,065 an ounce earlier in the trading day, the highest level since January 3 and nearly A month-long high.",
  "great_comment": "Great review",
  "no_comment": "There are no comments yet~",
  "write_comment": "Write a review",
  "info_sharing": "Information sharing",
  "likes": "praise",
  "brand_name": "Gold Fun",
  "read": "read",
  "disclaimer": "Disclaimer:",
  "footer_disclaimer": "The information on this site may inform you of certain precious metals investment-related products and services offered by Gold Fun Corporation Limited or any of its affiliates (together, \"GF Corp\"). No information published on the site constitutes a solicitation, offer or recommendation to enter any transaction. The products, services, information and/or materials referenced within these web pages may not be available for all individuals or residents of certain jurisdictions (including but not limited to the United States of America, Canada, Japan). Please ensure you are not restricted by the laws of your jurisdiction to access the products, services, information and/or materials referenced within these web pages before accessing any of them.",
  "header_1": "CGSE  Member",
  "header_2": "HKCE Registration No",
  "flexible_msg": "Flexible buying and selling, achieving the future",
  "go_to_one_like": "Go to OneLike Shop",
  "go_to_sampattisafar": "Go to SampattiSafar",
  "about_gold_fun": "About Gold Fun",
  "about_gold_fun_1": "Gold Fun Corporation Ltd, established in 2016, is a formal member of the Hong Kong Gold & Silver Exchange Society (Membership Number: 064, Operation Status: B) and is a Category A Registrant of the Hong Kong Customs and Excise Department. Focused on the precious metals business, we offer comprehensive one-stop services, encompassing gold refining, production, trade, investment, marketing, and price hedging. From the source at the gold mines to creating pure gold products, from secure transport and storage to investment hedging, our professional team and advisors tailor various trade and investment plans in precious metals for our clients, ensuring safety and reliability.",
  "about_gold_fun_2": "In recent years, we have established business collaborations with leading smelting service companies in Hong Kong, significantly increasing our daily processing volume and ensuring prompt and secure transactions. Looking to the future, Gold Fun Corporation Ltd is committed to improving its business structure and seizing opportunities in emerging industries according to the latest market trends. We actively expand in local and international markets, seeking reliable partners to drive the company's comprehensive development. With its honest, proactive, and professional approach, our team provides stable, efficient, and swift services to our clients, adapting to the rapidly evolving international precious metals market.",
  "local_melting_service": "Local smelting services",
  "local_melting_msg": "Through partners, we provide one-stop smelting services for overseas and local gold sources, including escort, customs clearance, warehousing, and delivery.",
  "hedge_service": "Hedging services",
  "hedge_service_msg": "Through \"Feng Lai Jin\" transactions, we provide hedging services for physical gold customers, making transactions simple and flexible, and reducing the risk of price changes.",
  "solid_gold_sale": "Real gold trading",
  "solid_gold_sale_msg": "Thousand pure gold, kilogram bars, branded bars, all available, large transaction volume, fast and safe delivery",
  "gfgold_msg": "Leveraged contract investment products, short-term investment, real gold hedging, extremely low fees, flexible and easy to use",
  "about_gold_fun_detail": "Gold Fund Corporation LTD, founded in 2016, is a official staff of the Hong Kong Gold and Silver Trade Stadium (Pigrarous Number: 064, Category of Business Status: B). It is also a category registrar registered by Hong Kong Customs. Essence We have always focused on precious metal business. We provide one -stop all -round services, including gold refining, production, trade, investment, and marketing and price hedging. From the source of gold ore to pure gold products, from escort warehouses to investment hedging, our professional team and consultant can tailor various precious metal trade and investment plans for customers, which are safe and reliable.",
  "click_to_see": "Click to see more",
  "login": "Trading Account (Login / Open Account)",
  "more": "learn more",
  "latest_news": "Latest news",
  "column_analysis": "Column analysis",
  "analysis_title_1": "2024-02-02 Sing Pao column \"Real Money\" - The situation in the Middle East worsens and gold is a safe haven",
  "analysis_title_2": "2024-01-26 Sing Pao column \"Real Gold and Silver\" - PMI data is strong, gold price falls",
  "analysis_title_3": "2024-01-19 Sing Pao column \"Real Money\" - US retail data is strong, gold price falls",
  "analysis_title_4": "2024-01-12 Sing Pao column \"Real Gold and Silver\" - US CPI strikes, gold price temporarily stable",
  "service_subtitle_1": "Expert remelting services to repurpose your gold.",
  "service_subtitle_2": "Exquisite diamond jewelry with timeless elegance.",
  "service_subtitle_3": "High-quality 12kg gold dore bars for serious investors.",
  "service_subtitle_4": "Pristine, 24-karat gold bars for secure investment.",
  "service_subtitle_5": "Advanced software for real-time gold trading analysis.",
  "service_subtitle_6": "Future contracts for gold to secure your investment.",
  "service_title_1": "Remelting Service",
  "service_title_2": "Diamond Jewelry",
  "service_title_3": "12kg Gold Dore",
  "service_title_4": "Pure Gold Bars",
  "service_title_5": "Realtime Trading Software",
  "service_title_6": "Gold Futures",
  "core_services": "Core services",
  "welcome_to_goldfun": "Welcome to Gold Fun",
  "banner_msg_1": "Invest in Certainty, Gold is the Future",
  "personal_consultant": "Personal Gold Investment Consultant",
  "banner_msg_2": "Have a Gold Investment problems?",
  "finanance_strength": "Finance strength is the cornerstone of our success",
  "our_services": " Our Services",
  "contact_us": "Contact Us",
  "learn_more": "Learn More",
  "address": "Address",
  "contact": "Contact Us",
  "opening_time": "Opening Time",
  "email": "email",
  "view_all_news": "View all News",
  "onelike_desc": "OneLike Shop is an online marketplace in collaboration with Gold Fun. The marketplace offers a wide range of products, including precious metal items, jade, jewellery, diamond accessories, gold bars, and ingots, all provided by Gold Fun Corporation Ltd with a guarantee of authenticity and quality—everything you need with an assurance of excellence.",
  "sampatti_desc": "SampattiSafar, developed by Gold Fun Corporation Ltd, is a platform tailored for the Indian market to facilitate trading in precious metals, utilizing international pricing standards to protect interests. It simplifies the investment process by integrating local payment gateways for 24/7 transactions, allowing investments as low as 200 Indian Rupees, and features automatic mechanisms to minimize losses and secure profits. Designed for convenience and efficiency, it offers quick settlements and easy account management, making it easier for users to engage in precious metal trading and achieve their financial goals with minimal barriers.",
  "gfgold_desc": "The financial service offers significant advantages by allowing investors to achieve substantial outcomes with minimal effort through leveraged gold/silver contracts, requiring only a 2% minimum margin. It provides the convenience of 24-hour trading on trade days, enabling traders to seize every opportunity. Additionally, the service boasts exceptionally low transaction fees, with the perk of commission-free trades after a certain volume is met. Moreover, it offers flexible settlement options, catering to both short-term investment strategies and physical hedging needs, by allowing settlements in either cash or physical gold. This combination of features makes it an attractive option for those looking to maximize their investment efficiency and flexibility.",
  "business_associates": "Business Associates",
  "service_title_7": "Storage Service",
  "service_title_8": "Tailor-made Trading",
  "service_title_9": "GF Gold Trading",
  "service_title_10": "Gold Investment",
  "service_title_11": "Gold Ore from Mines",
  "service_subtitle_7": "Safe & Secure Storage Solutions for Valuables",
  "service_subtitle_8": "Personalized Trading Plans for Precious Metals",
  "service_subtitle_9": "Exclusive GF Gold Trading for Elite Investors",
  "service_subtitle_10": "Comprehensive Gold Investment Services & Consultancy",
  "service_subtitle_11": "Direct Access to Prime Gold Ore from Renowned Mines",
  "home": "Home",
  "hedging_faq": "Hedging FAQ",
  "hedging_faq_title": "Gold Hedging Services provided by",
  "what_is_hedging": "What is “Hedging”?",
  "hedging_msg_1": "Hedging is a risk management strategy used to reduce\r\n                            the impact of price fluctuations in financial markets. It involves taking an offsetting position\r\n                            in a financial instrument that is negatively correlated with the original position, so as to\r\n                            reduce the potential losses from market volatility. The goal of hedging is to minimize the\r\n                            impact of market risk on an investment portfolio, ensuring that any adverse price movements in\r\n                            one product are partially or fully offset by gains in the other.",
  "hedging_msg_2": "As a physical gold trader, how do\n                                    you do hedging using a trading account from Gold Fun?",
  "hedging_msg_3": "Physical gold traders can use trading accounts to hedge\n                            their exposure to the price of gold by taking offsetting positions in gold contracts.",
  "hedging_msg_4": "For\r\n                                        those who bought gold and are waiting for delivery:",
  "hedging_msg_5": "If a trader is long physical gold and is concerned\r\n                            about a potential price decrease, they can sell gold contracts to lock in a selling price for\r\n                            their physical holdings. If the price of gold does in fact decrease, the loss on their physical\r\n                            gold holdings will be offset by the gain on their contract positions.",
  "hedging_msg_6": "For\r\n                                        those who sold products and need to buy gold later as raw material:",
  "hedging_msg_7": "If a trader is short physical gold and is concerned\r\n                            about a potential price increase, they can buy gold contracts to limit their potential losses.\r\n                            The use of trading accounts for hedging can help traders manage their risk and lock in\r\n                            profits.",
  "hedging_msg_8": "What is the best time to hedge and what is the best time\r\n                                    to liquidate my hedging positions?",
  "hedging_msg_9": "Hedging is typically used to manage risk, so the\r\n                            decision to hedge should be based on an assessment of the market environment and the investor's\r\n                            perception of future price movements, but typically, it should be done once a client is exposed\r\n                            to risks of price movements.",
  "hedging_msg_10": "As for liquidating hedging positions, the best time to\r\n                            do so would be when the original position no longer requires protection from market risk, or\r\n                            when market conditions have changed such that the hedge is no longer necessary or is no longer\r\n                            effective in managing risk.",
  "hedging_msg_11": "The use of stop-loss orders or other risk management\r\n                            strategies may also be appropriate to help manage risk and ensure a timely exit from a hedge,\r\n                            but we advise clients to liquidate their hedging positions when their original physical\r\n                            positions have also been cleared.",
  "hedging_msg_12": "Figure 1: Corresponding number of lots to weight of gold in kilogram in order to completely hedge the price",
  "gf_gold_title": "Shape your Future with GF Gold",
  "gf_gold_msg_1": "Archiving Great Results with Little Effort",
  "gf_gold_msg_2": "Invest in leveraged gold/silver contract",
  "gf_gold_msg_3": "Minimum margin requirement of only 2%",
  "gf_gold_msg_4": "Trade Anytime, Anywhere",
  "gf_gold_msg_5": "24-hour trading on trade days Seize every opportunity",
  "gf_gold_msg_6": "Exceptionally Low Transaction Fees",
  "gf_gold_msg_7": "Commission-free if a specific trading volume reached",
  "gf_gold_msg_8": "Cash or Physical Settlement",
  "gf_gold_msg_9": "Cash settlement, or opt for physical gold settlement after fully paid up",
  "gf_gold_msg_10": "Ideal for short-term investment or physical hedging",
  "seo_1": "Gold Fun Corporation Ltd, established in 2016, is a formal member of the Hong Kong Gold & Silver Exchange Society (Membership Number: 064, Operation Status: B) and is a Category A Registrant of the Hong Kong Customs and Excise Department. Focused on the precious metals business, we offer comprehensive one-stop services, encompassing gold refining, production, gold trading, gold investment, marketing, and price hedging. From the source at the gold mines to creating pure gold products, from secure transport and storage to investment hedging, our professional team and advisors tailor various trade and investment plans in precious metals for our clients, ensuring safety and reliability.",
  "seo_2": "Hong Kong Top Gold Trading Platform",
  "seo_3": "With our user-friendly online platform, clients can easily buy, sell, and trade gold. It offers advance charting tools, and real-time market data.",
  "about_us_title": "About Hong Kong GoldFun Trading Broker",
  "about_us_p1": "Gold Fun Corporation Ltd, established in 2016, is a formal member of the Hong Kong Gold & Silver Exchange Society (Membership Number: 064, Operation Status: B) and is a Category A Registrant of the Hong Kong Customs and Excise Department. Focused on the precious metals business, we offer comprehensive one-stop services, encompassing gold refining, production, gold trading, gold investment, marketing, and price hedging. From the source at the gold mines to creating pure gold products, from secure transport and storage to investment hedging, our professional team and advisors tailor various trade and investment plans in precious metals for our clients, ensuring safety and reliability.",
  "about_us_p2": "In recent years, we have established business collaborations with leading smelting service companies in Hong Kong, significantly increasing our daily processing volume and ensuring prompt and secure transactions. Looking to the future, Gold Fun Corporation Ltd is committed to improving its business structure and seizing opportunities in emerging industries according to the latest market trends. We actively expand in local and international markets, seeking reliable partners to drive the company's comprehensive development. With its honest, proactive, and professional approach, our team provides stable, efficient, and swift services to our clients, adapting to the rapidly evolving international precious metals market.\r",
  "about_us_p3": "Mission Statement",
  "about_us_p4": " At GoldFun, our goal is to give institutions and individuals easy access to transparent gold trading platforms so they can protect and increase their wealth in a constantly shifting financial environment.\r",
  "about_us_p5": "Storage Solutions",
  "about_us_p6": "GoldFun works with reputed storage facilities to provide customers with secure and insured storage options for their physical gold holdings, ensuring peace of mind and security against theft or loss.\r",
  "about_us_p7": "Hong Kong Top Gold Trading Platform",
  "about_us_p8": "With our user-friendly online platform, clients can easily buy, sell, and trade gold. It offers sophisticated charting tools, and real-time market data.",
  "about_us_p9": "Personalized Support",
  "about_us_p10": "Our team of seasoned experts is committed to giving each customer individual attention. We do this by providing portfolio analysis, customized investment advice, and direction to help clients maximize their gold trading techniques.",
  "about_us_p11": "Commitment to Excellence",
  "about_us_p12": " In all of our interactions, GoldFun upholds the greatest standards of honesty, openness, and competence. Building long-term relationships with our customers based on mutual respect, trust, and a shared desire for financial success is our mission.",
  "about_us_p13": "Join GoldFun today and start exploring the financial benefits of gold trading.",
  "risk_closure": "Risk Disclosure: Trading precious metals carries a high level of risk to your capital, and you should only trade with money you can afford to lose. Trading precious metals may not be suitable for all investors, so please ensure that you fully understand the risks involved and seek independent advice if necessary. Please read and ensure you fully understand our Risk Disclosure.",
  "privacy_policy": "Privacy Policy",
  "terms": "Terms & Conditions"
};